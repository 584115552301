
import { getReservationReviewList, getReservationReviewMypageTotal } from '@/api/reservationReview';
import { getStoreInfo } from '@/api/storeInfo';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';
import { addRecentList } from '@/utils/localStorage';
import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { addViewCount } from '@/api/hits';
import { likeReview } from '@/api/reservationReviewLike';
import Slick from 'vue-slick';
import { Store } from './store';

@Component({
  components: {
    Slick,
  },
})

export default class extends Vue {
  created() {
    this.initKakao();
    this.slickLoading();
  }

  mounted() {
    this.getStoreInfo();
    this.getReviewList();
    this.checkContent();
  }

  @Watch('storeInfo.courseInfo')
  onCourseInfoChanged(): void {
    this.checkContent();
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private loading = true;

  private locationVisible = false;

  private informationStatus = '';

  private handleInformationMore() {
    this.informationStatus = '--open';
    this.informationStatus = 'expanded';
  }

  private active = '';

  private showImgCutButton = true;

  private map = null;

  private currentIndex = 0; // 슬라이드현재페이지

  private datePickerVisible = false;

  private storeInfo = {
    name: '',
    introduce: '',
    tags: [],
    address: '',
    addressDetail: '',
    lat: '',
    lng: '',
    inquiryTel: '',
    operationTimeInfo: '',
    equipmentInfo: '',
    reserveUrl: '',
    distance: '',
    images: [],
    companyName: '',
    bossName: '',
    companyAddress: '',
    companyAddressDetail: '',
    profileFileUid: '',
    greeting: '',
    minPrice: 0,
    reviewCount: 0,
    avgScore: '0.0',
    basicInfo: '',
    parkingInfo: '',
    entranceGuide: '',
    locationInfo: '',
    surroundInfo: '',
    operations: [],
    operationList: [],
    etcInfo: '',
    courseInfo: '',
    newsList: [],
    etc: '',
  }

  private detailQuery: any = {
    lat: '37.566826',
    lng: '126.9786567',
    useDate: moment().format('YYYY-MM-DD'),
  }

  private listQuery = {
    useDate: moment().format('YYYY-MM-DD'),
    people: 1,
    round: 0,
  }

  private reserveForm = {
    ticket1: 1,
    ticket2: 1,
    ticket3: 1,
  }

  private handleClickSlick(refName: string, type: string) {
    if (this.$refs[refName]) {
      const reference: any = this.$refs[refName];
      if (type === 'prev') reference.prev();
      if (type === 'next') reference.next();
    }
  }

  private mainslickOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 400,
    // prevArrow: $('.iconslick-prev'),
    // nextArrow: $('.iconslick-next'),
    // draggable: true,
    pauseOnHover: true,
    arrows: false,
  };

  private slickLoad = false;

  private isLoading = true;

  private slickLoading() {
    // console.log('시작');
    this.isLoading = true;
    window.setTimeout(() => {
      this.isLoading = false;
      this.slickLoad = true;
      // console.log('종료');
    }, 3000);
  }

  private review = {
    propileName: '루피재욱',
    propileAmount: '55',
    propilePhoto: '15',
    rate: 4,
    likeCount: 0,
  }

  private reviewList = [];

  private reviewQuery = {
    page: 0,
    size: 10,
    businessIdx: this.$route.params.idx,
  }

  private reviewNoMore = false;

  private getStoreInfo() {
    const lat = getLat();
    const lng = getLng();
    if (lat && lng) {
      this.detailQuery.lat = lat;
      this.detailQuery.lng = lng;
      this.getStoreInfoDetail();
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.detailQuery = {
            ...this.detailQuery,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          this.getStoreInfoDetail();
        }, (error) => {
          this.getStoreInfoDetail();
        },
      );
    }
  }

  private storeSplit = {
    basicInfo: [] as Object[],
    parkingInfo: [] as Object[],
    entranceGuide: [] as Object[],
    locationInfo: [] as Object[],
    surroundInfo: [] as Object[],
    operations: [] as Object[],
    operationTimeInfo: [] as Object[],
    operationList: [] as Object[],
    etcInfo: [] as Object[],
    newsList: [] as Object[],
  }

  private formattedBasicInfo(store: any) {
    if (store) {
      this.storeSplit.basicInfo = store.basicInfo ? store.basicInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.parkingInfo = store.parkingInfo ? store.parkingInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.entranceGuide = store.entranceGuide ? store.entranceGuide.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.locationInfo = store.locationInfo ? store.locationInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.surroundInfo = store.surroundInfo ? store.surroundInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.operationTimeInfo = store.operationTimeInfo ? store.operationTimeInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.operations = store.operations ? store.operations : ['입력된 정보가 없습니다'];
      this.storeSplit.operationList = store.operationList ? store.operationList : ['입력된 정보가 없습니다'];
      this.storeSplit.etcInfo = store.etcInfo ? store.etcInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.newsList = store.newsList[0].content ? store.newsList[0].content.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
    }
  }

  private getStoreInfoDetail() {
    this.loading = true;
    getStoreInfo(this.$route.params.idx, this.detailQuery).then((res) => {
      addRecentList(res.data, window.location.pathname);
      this.storeInfo = res.data;
      this.storeInfo.images = [...this.storeInfo.images].reverse();
      this.loading = false;
      this.formattedBasicInfo(this.storeInfo);
    }).then(() => {
      addViewCount({ businessIdx: this.$route.params.idx });
    });
  }

  private handleViewLocation() {
    window.open(`https://map.kakao.com/link/map/${this.storeInfo.name},${this.storeInfo.lat},${this.storeInfo.lng}`);
    // window.open(`https://map.kakao.com/link/to/${this.storeInfo.name},${this.storeInfo.lat},${this.storeInfo.lng}`);
    if (this.storeInfo.lat && this.storeInfo.lng) {
      this.locationVisible = !this.locationVisible;
      this.$nextTick(() => {
        this.initMap(this.storeInfo.lat, this.storeInfo.lng);
      });
    } else {
      this.$message.error('지도를 불러오는데 실패했습니다.');
    }
  }

  private initKakao() {
    if (!(window as any).kakao || (window as any).kakao.maps) {
      const script = document.createElement('script');
      script.src = `${window.location.protocol}//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.VUE_APP_KAKAO_CLIENT_ID}&libraries=services`;
      document.head.appendChild(script);
    }
  }

  private initMap(lat: any, lng: any) {
    const container = document.getElementById('map');
    const option = {
      center: new (window as any).kakao.maps.LatLng(lat, lng),
      level: 3,
    };
    this.map = new (window as any).kakao.maps.Map(container, option);
    const markerPosition = new (window as any).kakao.maps.LatLng(lat, lng);
    const marker = new (window as any).kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(this.map);
    const content = `<div class="gis-item">${this.storeInfo.name}</div>`;
    const customOverlay = new (window as any).kakao.maps.CustomOverlay({
      map: null,
      position: markerPosition,
      content,
      yAnchor: 2.4,
      visible: false,
    });
    (window as any).kakao.maps.event.addListener(marker, 'mouseover', () => {
      customOverlay.setMap(this.map);
    });
    (window as any).kakao.maps.event.addListener(marker, 'mouseout', () => {
      customOverlay.setMap(null);
    });
    (window as any).kakao.maps.event.addListener(marker, 'click', () => {
      (window as any).open(`https://map.kakao.com/link/map/${this.storeInfo.name},${lat},${lng}`);
    });
  }

  private getReviewList() {
    getReservationReviewList(this.reviewQuery).then((res) => {
      this.reviewList = this.reviewList.concat(res.data.content);
      this.reviewNoMore = res.data.last;
    });
  }

  private handleMoreReviewList() {
    this.reviewQuery.page += 1;
    this.getReviewList();
  }

  private likeStatus = false;

  private totalLike = 0;

  private likeList = [];

  private totalReview = 0;

  private handleClickAccomodationSlide(str: string) {
    const slick = (this.$refs.accomodationBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private handleClickLike(review: any) {
    likeReview(review.idx).then(() => {
      if (review.likeStatus) {
        this.$message.success('리뷰 좋아요를 취소했습니다.');
        review.likeStatus = false;
        review.likeCount -= 1;
      } else {
        this.$message.success('리뷰 좋아요를 등록했습니다.');
        review.likeStatus = true;
        review.likeCount += 1;
      }
      getReservationReviewMypageTotal().then((res) => {
        this.totalReview = res.data.totalReview;
        this.totalLike = res.data.totalLike;
      });
    }).catch(() => {
      this.$message.error('요청에 실패했습니다.');
    });
    getReservationReviewMypageTotal().then((res) => {
      this.totalReview = res.data.totalReview;
      this.totalLike = res.data.totalLike;
    });
  }

  private handleCancelLike(like: any, index: number) {
    likeReview(like.idx).then(() => {
      this.$message.success('리뷰 좋아요를 취소했습니다.');
      this.likeList.splice(index, 1);
      getReservationReviewMypageTotal().then((res) => {
        this.totalReview = res.data.totalReview;
        this.totalLike = res.data.totalLike;
      });
    }).catch(() => {
      this.$message.error('요청에 실패했습니다.');
    });
  }

  private getPrivateData() {
    const privateData = this.review.likeCount;
    return privateData;
  }

  private ShowButton() {
    let str = this.$data.storeInfo.introduce;
    if (str === null) str = '';
    const introduction = str;
    const introductionLength = introduction.length;
    const windowWidth = this.getWindowWidth();

    if (windowWidth >= 1024) {
      return this.$data.informationStatus !== 'expanded' && introductionLength >= 450;
    }
    return this.$data.informationStatus !== 'expanded' && introductionLength >= 150;
  }

  private getWindowWidth(): number {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  private ShowArrowButton(): boolean {
    const tagsLength = this.$data.storeInfo.tags.length;
    return this.getWindowWidth() >= 1024 ? tagsLength > 10 : tagsLength > 4;
  }

  // 슬라이드 현재페이지 숫자
  private carouselChange(index: number): void {
    if (this.storeInfo.images.length <= index) {
      this.currentIndex -= 1;
    } else {
      this.currentIndex = index;
    }
  }

  private handleKakaoShare() {
    const baseUrl = process.env.VUE_APP_TARGET;
    const shareUrl = window.location.href;
    (window as any).Kakao.cleanup();
    (window as any).Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY);
    (window as any).Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: this.storeInfo.name,
        description: this.storeInfo.introduce,
        imageUrl: this.storeInfo.images.length > 0 ? `${baseUrl}/api/attached-file/${this.storeInfo.images[0]}` : `${baseUrl}/favicon.ico`,
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      social: {
        likeCount: 0,
        commentCount: 0,
        sharedCount: 0,
      },
      buttons: [
        {
          title: '보러가기',
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
      ],
    });
  }

  private curTabs: number[] = [1, 2, 3];

  private toggleSection(section: number): void {
    const index = this.curTabs.indexOf(section);
    if (index !== -1) {
      // 섹션이 이미 열려있는 경우 닫기
      this.curTabs.splice(index, 1);
    } else {
      // 새로운 섹션 열기
      this.curTabs.push(section);
    }

    // 아이콘 클래스 업데이트
    for (let i = 1; i <= 4; i += 1) {
      const icon = this.$refs[`icon${i}`] as HTMLElement;
      const isOpen = this.curTabs.includes(i);
      if (isOpen) {
        icon.classList.remove('el-icon-arrow-down');
        icon.classList.add('el-icon-arrow-up');
      } else {
        icon.classList.remove('el-icon-arrow-up');
        icon.classList.add('el-icon-arrow-down');
      }
    }
  }

  private isSectionVisible(section: number): boolean {
    return this.curTabs.includes(section); // 현재 섹션의 가시성 여부 반환
  }

  private imgCut = false;

  private textCut = false;

  private imgCutText = '더보기';

  private imgAll() {
    this.imgCut = !this.imgCut;

    this.imgCutText = this.imgCut ? '접기' : '더보기';
  }

  private checkContent(): void {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.storeInfo.courseInfo, 'text/html');
    const hasImage = doc.querySelector('img') !== null;
    // console.log(`hasImage: ${hasImage}`);
    const contentElement = this.$el.querySelector('.content') as HTMLElement;
    if (contentElement) {
      const lineHeight = parseInt(getComputedStyle(contentElement).lineHeight, 10);
      const contentHeight = contentElement.offsetHeight;
      const lines = contentHeight / lineHeight;
      if (!hasImage) {
        this.imgCut = false;
        this.textCut = true;
        this.showImgCutButton = false;
      } else {
        this.textCut = false;
        this.showImgCutButton = true;
      }
    }
  }

  private formatTime(str: string) {
    const start = str.substring(0, 2);
    const end = str.substring(2, str.length);
    const result = `${start}:${end}`;

    return result;
  }
}
